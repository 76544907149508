<template>
	<el-form :model="templateMsgForm" ref="templateMsgForm" label-width="140px">
		<div v-for="(item, index) in templateMsgDataForm" :key="index">
			<el-form-item :label="item.label" v-if="item.isInput != false" :rules="item.rules" :prop="item.attr">
				<el-input v-if="item.type == 'textarea'" type="textarea" v-model="templateMsgForm[item.attr]" :placeholder="item.placeholder"></el-input>
				<el-select v-else-if="item.type == 'enum'" v-model="templateMsgForm[item.attr]" :placeholder="item.placeholder" filterable>
					<el-option v-for="ite in item.dic" :key="ite.value" :label="ite.title" :value="ite.value"> </el-option>
				</el-select>
				<el-input v-else v-model="templateMsgForm[item.attr]" :placeholder="item.placeholder"></el-input>
			</el-form-item>
		</div>
		<div class="el-form-item-msg" style="line-height: 20px">
			<font color="red">*</font>注：一种消息类型对应一条模板消息配置。
		</div>
		<el-form-item>
			<el-button type="primary" @click="submitForm('templateMsgForm')">保存</el-button>
			<el-button @click="resetForm('templateMsgForm')">重置</el-button>
		</el-form-item>
	</el-form>
</template>
<script>
export default {
	name: "saveTemplateMsg",
	props: {
		tempid: {
			type: Number,
			default: 0,
		},
		appid: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			templateMsgForm: {
				Id: 0,
				TemplateTitle: "",
				TemplateId: "",
				AppID: "",
				Url: "",
				MiniprogramAppID: "",
				MiniprogramPagePath: "",
				MsgTpye: "",
				RemarkInfo: "",
			},
			templateMsgDataForm: [
				{
					label: "Id",
					attr: "Id",
					isInput: false,
				},
				{
					label: "模板标题",
					attr: "TemplateTitle",
					placeholder: "请输入模板标题",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入模板标题",
						},
					],
				},
				{
					label: "模板ID",
					attr: "TemplateId",
					placeholder: "请输入模板ID",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入模板ID",
						},
					],
				},
				{
					label: "消息类型",
					attr: "MsgTpye",
					placeholder: "请选择消息类型",
					rules: [
						{
							required: true,
							message: "请选择消息类型!",
							trigger: "change",
						},
					],
					type: "enum",
					dic: this.getMsgTypes(),
				},
				{
					label: "模板跳转链接",
					attr: "Url",
					placeholder: "请输入模板跳转链接",
				},
				{
					label: "跳转小程序appid",
					attr: "MiniprogramAppID",
					placeholder: "请输入模板跳转小程序appid",
				},
				{
					label: "跳转小程序页面路径",
					attr: "MiniprogramPagePath",
					placeholder: "请输入模板跳转小程序页面路径",
				},
				{
					label: "备注",
					attr: "RemarkInfo",
					placeholder: "请输入备注（长度不大于100个字符）",
					rules: [
						{
							min: 0,
							max: 100,
							message: "长度不大于100个字符",
							trigger: "blur",
						},
					],
					type: "textarea",
				},
			],
		};
	},
	created() {
		var that = this;
		that.templateMsgForm.AppID = that.$props.appid;
		that.templateMsgForm.Id = that.$props.tempid;
	},
	methods: {
		initEditModel() {
			var that = this;
			if (that.$props.tempid != 0) {
				that.$API.WeChatConfigTemplateMsg.QueryById.get(that.$props.tempid).then((res) => {
					that.templateMsgForm = res;
				});
			}
		},
		getMsgTypes() {
			var that = this;
			var data = [];
			that.$API.WeChatConfigTemplateMsg.GetMsgTypes.get().then((res) => {
				if (res) {
					res.forEach((it) => {
						data.push({ value: it.Key, title: it.Value });
					});
				}
				that.initEditModel();
			});
			return data;
		},
		submitForm(formName) {
			var that = this;
			console.info(that.templateMsgForm);
			that.$refs[formName].validate((valid) => {
				if (valid) {
					that.$API.WeChatConfigTemplateMsg.AddOrUpdate.post(that.templateMsgForm).then((res) => {
						if (res.Success) {
							that.$message.success(res.Message);
							that.$emit("operationSuccess");
						} else {
							that.$message.error(res.Message);
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
	},
};
</script>

<style></style>
